var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        { attrs: { slot: "header", title: "预览", fixed: "" }, slot: "header" },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: {
                click: function ($event) {
                  return _vm.$router.back()
                },
              },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "iframeContainer",
          staticStyle: {
            "-webkit-overflow-scrolling": "touch",
            overflow: "auto",
          },
        },
        [
          _c(
            "iframe",
            {
              attrs: {
                src: _vm.iframeUrl,
                width: "100%",
                height: "100%",
                frameborder: "0",
              },
            },
            [
              _vm._v("这是嵌入 "),
              _c(
                "a",
                { attrs: { target: "_blank", href: "https://office.com" } },
                [_vm._v("Microsoft Office")]
              ),
              _vm._v(" 文档，由 "),
              _c(
                "a",
                {
                  attrs: {
                    target: "_blank",
                    href: "https://office.com/webapps",
                  },
                },
                [_vm._v("Office Online")]
              ),
              _vm._v(" 支持。"),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }