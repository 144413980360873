<template>
  <fb-page>
    <!-- header -->
    <fb-header slot="header"
               :title="'预览'"
               fixed>
      <fb-button slot="left"
                 icon="back"
                 @click="$router.back()"
                 size="small"
                 type="primary">
        返回
      </fb-button>
    </fb-header>
    <div class="iframeContainer"
         style="-webkit-overflow-scrolling:touch;overflow:auto;overflow:auto;">
      <iframe :src="iframeUrl"
              width="100%"
              height="100%"
              frameborder="0">这是嵌入 <a target="_blank"
           href="https://office.com">Microsoft Office</a> 文档，由 <a target="_blank"
           href="https://office.com/webapps">Office Online</a> 支持。</iframe>
    </div>
  </fb-page>
</template>

<script>
export default {
  data () {
    return {
      iframeUrl: ''
    }
  },
  created () {
    const { url } = this.$route.params
    this.iframeUrl = url
  }
}
</script>

<style>
.iframeContainer {
  height: calc(100vh - 40px);
}
</style>
